import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { Select } from "antd";
import "./chart.scss";

Chart.register(...registerables);

const { Option } = Select;

const SubscriptionChart = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 25 }, (_, i) => 2024 - i).filter(
    (year) => year >= 2024
  );

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Monthly Subscriptions",
        data: [200, 400, 300, 300, 200, 400, 400, 100, 300, 300, 400, 500],
        backgroundColor: "#7B61FF",
        borderColor: "#7B61FF",
        borderWidth: 1,
        barThickness: 36,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          // display: false, // Uncomment to hide vertical grid lines
        },
        ticks: {
          color: "#000000", // Color of the x-axis labels
        },
        border: {
          display: true,
          color: "#000000", // Color of the x-axis border
          width: 2, // Thickness of the x-axis border
        },
      },
      y: {
        grid: {
          // display: false, // Uncomment to hide horizontal grid lines
        },
        ticks: {
          color: "#000000", // Color of the y-axis labels
        },
        border: {
          display: true,
          color: "#000000", // Color of the y-axis border
          width: 2, // Thickness of the y-axis border
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        align: "end",
        anchor: "end",
        color: "#000000", // Color of the data labels
        borderWidth: 1,
        borderColor: "#000000", // Border color for the data labels
        backgroundColor: "#FFFFFF", // Background color for the data labels
        padding: 4, // Padding for the data labels
        formatter: function (value) {
          return value; // Show the value as it is
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="col-12 my-lg-4 pt-lg-2 pb-5">
      <div className="bar-chart pb-4">
        <div className="d-flex justify-content-between align-items-center px-4 mx-2 py-4">
          <span className="mb-0 bar-chart-text1">Monthly Subscriptions</span>

          <Select
            style={{ width: "100px", height: "33px" }}
            className="dash-select"
            defaultValue={currentYear}
          >
            {years.map((year) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </div>

        <div className="bar-chart-padding">
          <div className="pt-3" style={{ height: "350px" }}>
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionChart;
