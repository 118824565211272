import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginWithEmail } from "../../services/service";
import { CustomToast } from "../../atoms/toastMessage";
import { useNavigate } from "react-router-dom";

// Thunk for logging in
export const loginUser = createAsyncThunk(
  "auth/loginUser",

  async (credentials, { rejectWithValue }) => {
    try {
      const response = await loginWithEmail(credentials);

      if (response?.data) {  
        CustomToast({
          type: "success",
          message: `${response?.data?.role} is signed in`,
        });
        localStorage.setItem("token", response?.access_token);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500); 
        return {
          user: response.data, // Full user object
          token: response.access_token,
        };
      } else {
        throw new Error("Invalid Email or Password");
      }
    } catch (error) {
      CustomToast({ type: "error", message: "Invalid Email or Password" });
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
  },
  reducers: {
    clearAuthentication: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.user = null;
        state.token = null;
      });
  },
});

export const { clearAuthentication } = authSlice.actions;

export default authSlice.reducer;
